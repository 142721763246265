/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const PlayIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M4.1 2.4L19.9 12 4.1 21.6V2.4zm0-1.4c-.8 0-1.4.6-1.4 1.4v19.2c0 .8.6 1.4 1.4 1.4.3 0 .5-.1.7-.2l15.9-9.6c.6-.4.8-1.2.4-1.9-.1-.2-.3-.3-.4-.4L4.8 1.2c-.2-.1-.5-.2-.7-.2z" />
	</svg>
))
PlayIconSvg.displayName = 'PlayIconSvg'

const PlayIcon = forwardRef((props, ref) => <Icon component={PlayIconSvg} ref={ref} {...props} />)
PlayIcon.displayName = 'PlayIcon'

PlayIcon.defaultProps = {
	...Icon.defaultProps,
}
PlayIcon.propTypes = {
	...Icon.propTypes,
}

export default PlayIcon
export { PlayIconSvg }
