import dynamic from 'next/dynamic'
import bannerConfigAggregator from './banner/config-aggregator'
import slideConfigAggregator from './slide/config-aggregator'
import tabsConfigAggregator from './tabs/config-aggregator'

import BannerMenuShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-menu/banner-menu-shimmer'
import BannerCategoryShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-category/banner-category-shimmer'
import BannerBoxedTextShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-boxed-text/banner-boxed-text-shimmer'
import LookBannerProductContextShimmer from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/look-banner-product-context/look-banner-product-context-shimmer'
import TabsShimmer from './tabs/tabs-shimmer'

const BannerMenu = dynamic(
	() => import('@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-menu'),
	{ loading: BannerMenuShimmer }
)

const BannerCategory = dynamic(
	() => import('@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-category'),
	{ loading: BannerCategoryShimmer }
)

const BannerBoxedText = dynamic(
	() => import('@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/banner-boxed-text'),
	{ loading: BannerBoxedTextShimmer }
)

const LookBannerProductContext = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/look-banner-product-context'
		),
	{ loading: LookBannerProductContextShimmer }
)

const TabsEconomiaCircolare = dynamic(
	() =>
		import(
			'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/tabs-economia-circolare/tabs-economia-circolare'
		),
	{ loading: TabsShimmer }
)

const bannerMenuConfig = { configAggregator: bannerConfigAggregator, component: BannerMenu }
const bannerCategoryConfig = { configAggregator: bannerConfigAggregator, component: BannerCategory }
const bannerBoxedTextConfig = { configAggregator: bannerConfigAggregator, component: BannerBoxedText }
const slideBoxedTextConfig = { configAggregator: slideConfigAggregator, component: BannerBoxedText }
const lookBannerProductContextConfig = { configAggregator: bannerConfigAggregator, component: LookBannerProductContext }
const tabsEconomiaCircolareConfig = { configAggregator: tabsConfigAggregator, component: TabsEconomiaCircolare }

const customContentTypes = {
	'banner-menu': bannerMenuConfig,
	'banner-category': bannerCategoryConfig,
	'banner-boxed-text': bannerBoxedTextConfig,
	'slide-boxed-text': slideBoxedTextConfig,
	'generic-look-product-context': lookBannerProductContextConfig,
	'tabs-economia-circolare': tabsEconomiaCircolareConfig,
}

export default customContentTypes
