import { styled } from '@mui/system'
import Button from '../../molecules/button'

export const Root = styled('div', {
	shouldForwardProp: (prop) =>
		!['ratio', 'background', 'playIconVerticalAlign', 'playIconHorizontalAlign', 'aboveTheFold'].includes(prop),
})`
	position: ${({ aboveTheFold }) => (aboveTheFold ? 'absolute' : 'relative')};
	width: 100%;
	height: 100%;
	align-self: ${({ aboveTheFold }) => (aboveTheFold ? 'center' : undefined)};
	${({ ['data-bhhide']: hide }) => (hide ? 'display: none !important;' : '')}
	${({ ratio, background, aboveTheFold }) =>
		ratio && (!background || aboveTheFold)
			? `
			padding-top: ${100 / ratio}%;
		`
			: ''};
	.react-player__preview {
		align-items: ${({ playIconVerticalAlign }) => playIconVerticalAlign} !important;
		justify-content: ${({ playIconHorizontalAlign }) => playIconHorizontalAlign} !important;
	}
`

export const SwiperOverlay = styled('div', { shouldForwardProp: (prop) => !['playing'].includes(prop) })`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	cursor: pointer;

	${({ playing }) => (playing ? 'display: none;' : '')}
	${({ type }) => {
		switch (type) {
			case 'youtube':
				return `
					height: calc(100% - 60px - 45px);
					top: 60px;
					/* uncomment to debug */
					/* background: rgba(150, 0, 0, 0.5); */
				`
			case 'file':
				return 'display: none;'
			case 'vimeo':
				return `
					width: calc(100% - 46px);
					height: calc(100% - 50px);
					/* uncomment to debug */
					/* background: rgba(150, 0, 0, 0.5); */
				`
		}
	}}
`

export const PlayButton = styled(Button)`
	background-color: #ffff;
	z-index: 2;
	padding: ${({ theme }) => theme.spacing(3)};
	color: ${({ theme }) => theme.palette.primary.main};
	.MuiSvgIcon-root {
		font-size: 24px;
	}
	&:hover {
		background-color: rgba(255, 255, 255, 0.85);
	}
	&:active {
		background-color: rgba(255, 255, 255, 0.7);
	}
`
